const Global = {
  title: "Identity Switcher",
  icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -140 507.96 507.96"><defs></defs><title>Asset 8</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M386.45,12.93a61,61,0,0,1,60.94,60.93,60.94,60.94,0,0,1-121.87,0,61,61,0,0,1,60.93-60.93m0-12.93a73.86,73.86,0,1,0,73.86,73.86A73.86,73.86,0,0,0,386.45,0Z"/><path d="M501.49,227.22a6.47,6.47,0,0,1-6.44-5.95,108.9,108.9,0,0,0-38.32-74.61,96,96,0,0,1-140.55,0A108.92,108.92,0,0,0,277.88,221,6.46,6.46,0,1,1,265,220a121.87,121.87,0,0,1,48.09-87.38l5-3.76,3.93,4.83a83.07,83.07,0,0,0,128.93,0l3.93-4.83,5,3.76a121.92,121.92,0,0,1,48.12,87.67,6.46,6.46,0,0,1-5.94,7Z"/><path d="M115,0a73.86,73.86,0,1,0,73.86,73.86A73.86,73.86,0,0,0,115,0Z"/><path d="M184.49,137.74a89.53,89.53,0,0,1-139,0A115.27,115.27,0,0,0,0,220.48,781.38,781.38,0,0,0,115.94,229a781.93,781.93,0,0,0,114.12-8.21A115.29,115.29,0,0,0,184.49,137.74Z"/></g></g></svg>`,
  theme: {
    colors: {
      topBar: {
        background: '#4123ff',
      },
    },
    logo: {
      width: 220,
      topBarSource: "/assets/Identity-Switcher-Logo.svg",
      url: "https://www.identityswitcher.app",
      accessibilityLabel: "Identity Switcher",
    }
  },
  footerLink: {
    url: "https://apps.support.code.nl/hc/en-us/articles/360003557617-How-do-I-assume-a-customer-as-an-agent"
  }
};

export default Global;