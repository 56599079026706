import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Card,
  Tabs,
} from '@shopify/polaris';
import CustomersList from './CustomersList';

const Customers = props => {

  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);
  const [queryAll, setQueryAll] = useState(props.isSuperAgent);

  const handleTabChange = useCallback(selectedTabIndex => {
    setSelectedTab(selectedTabIndex);
    setQueryAll(selectedTabIndex === 0 ? true : false);
  }, []);

  // Define tabs (Polaris)
  const tabs = [
    {
      id: 'all',
      content: t('allCustomers'),
      accessibilityLabel: t('allCustomersLong'),
      panelID: 'all-customers'
    },
    {
      id: 'assigned',
      content: t('assignedCustomers'),
      accessibilityLabel: t('assignedCustomersLong'),
      panelID: 'assigned-customers'
    }
  ];

  const listMarkup = (
    <CustomersList
      queryAll={queryAll}
      customerName={props.customerName} 
    />
  );

  const tabsMarkup = (
    <Tabs
      tabs={tabs}
      selected={selectedTab}
      onSelect={handleTabChange}
    >
    </Tabs>
  );

  return (
    <Card>
      { props.isSuperAgent && tabsMarkup }
      { listMarkup }
    </Card>
  );
}

export default Customers;