import React from 'react';
import { Page } from '@shopify/polaris';

const ErrorPage = props => {

  // extract only the requires properties
  const { status } = props;

  return (
    <Page title={`Error: ${status}`}>
    </Page>
  );
}

export default ErrorPage;