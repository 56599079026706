import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Badge, 
  Button, 
  Card,
  Pagination,
  ResourceList,
  Stack,
  TextStyle
} from '@shopify/polaris';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import functionsConfig from '../../config/Functions';

const CustomersList = props => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fetchDelayTimer, setFetchDelayTimer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [loggedInCustomerId, setLoggedInCustomerId] = useState(sessionStorage.getItem('identity-switcher-customer-id'));
  const [searchValue, setSearchValue] = useState("");
  const [cursorFirstRow, setCursorFirstRow] = useState(null);
  const [cursorLastRow, setCursorLastRow] = useState(null);

  const fetchCustomers = paginateAction => {
    setLoading(true);

    // init the search query
    let searchQuery = {
      limit: 25,
      search: searchValue
    }

    // if any type of pagination is set, add this to the search query
    switch (paginateAction) {
      case "previous":
        searchQuery.before = cursorFirstRow;
        break;
        case "next":
        searchQuery.after = cursorLastRow;
        break;
      default:
        // empty
        break;
    }
    
    firebase.auth().currentUser.getIdToken()
      .then(idToken => {
        return axios({
          method: "post",
          url: functionsConfig.search,
          params: props.queryAll && {
            all: 1
          },
          headers: {
            authorization: "Bearer " + idToken
          },
          timeout: 15000,
          data: searchQuery
        });
      })
      .catch(error => {
        // clear customers
        setLoading(false);
        setCustomers([]);
      })
      .then(response => {
        setLoading(false);
        setCustomers(response.data.data);
        setCursorFirstRow(response.data.meta.cursorFirstRow);
        setCursorLastRow(response.data.meta.cursorLastRow);
      });
  }

  useEffect(() => {
    fetchCustomers();
  }, []);

  // Fetch customers on query change
  useEffect(() => fetchCustomers(), [props.queryAll]);

  // Fetch customers on search
  useEffect(() => {
    clearTimeout(fetchDelayTimer);
    setFetchDelayTimer(setTimeout(() => {
      fetchCustomers();
    }, 500));
  }, [props.queryAll, searchValue]);

  const renderCustomer = item => {
    const [ id, name, company ] = item;
    const normalMarkup = (
      <div className="customer-list-item">
        <Stack 
          wrap={false} 
          alignment="center"
        >
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
            <TextStyle variation="subdued">{company}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <Button 
              plain
              onClick={() => { return switchUser(firebase.auth(), id, name); }}
            >
              {t('Login')}
            </Button>
          </Stack.Item>
        </Stack>
      </div>
    );

    const switchedMarkup = (
      <div className="customer-list-item">
        <Stack
          wrap={false}
          alignment="center"
        >
          <Stack.Item>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
          </Stack.Item>
          <Stack.Item>
            <Badge
              status="attention"
              children="Switched Identity"
            />
          </Stack.Item>
        </Stack>
      </div>
    );

    return (
      <ResourceList.Item id={id}>
        { id === loggedInCustomerId ? switchedMarkup : normalMarkup }
      </ResourceList.Item>
    )
  }

  const renderPagination = () => {
    // guard: need something to paginate with
    if (cursorFirstRow === null && cursorLastRow === null) {
      return '';
    }

    const hasPrevious = typeof cursorFirstRow === "string";
    const hasNext = typeof cursorLastRow === "string";

    return (
      <Card.Section>
        <center>
          <Pagination
            onPrevious={() => {
              fetchCustomers("previous");
            }}
            onNext={() => {
              fetchCustomers("next");
            }}
            hasPrevious={hasPrevious}
            hasNext={hasNext}
          />
        </center>
      </Card.Section>
    )
  }

  const switchUser = (firebaseAuth, customerId, name) => {
    // set the table in a loading state
    setLoading(true);

    const currentWindow = window;

    // check for mobile
    const isMobile = detectMobile();

    // name the browser window
    const browserWindowName = "IdentitySwitcher"

    // in order to prevent popup blocking, open directly a window on the user's action
    const customerWindow = window.open("/switch", browserWindowName);

    return firebase.auth()
      .currentUser.getIdToken()
      .then(idToken => {
        return axios({
          method: "get",
          url: `${functionsConfig.switch}?customer_id=${customerId}`,
          headers: {
            authorization: `Bearer ${idToken}`
          },
          timeout: 15000
        });
      })
      .then(response => {
        // if we have success
        if (response.status === 200) {
          // reuse the already opened window and relocate it's location
          window.open(response.data.url, browserWindowName);
          window.focus(); // force focus of the window

          // set the id to state
          setLoggedInCustomerId(customerId);
          // send name to top bar component via function as prop
          props.customerName(name);
          // store the customer name and id in the session so that it persists during the session (in case of page reload)
          sessionStorage.setItem('identity-switcher-customer-name', name);
          sessionStorage.setItem('identity-switcher-customer-id', customerId);

          if (isMobile === true) {
            currentWindow.close();
          }
        // no other state allowed
        } else {
          // close the already opened window window
          customerWindow.close();
        }
      })
      .catch(error => {
        // close the already opened window window
        customerWindow.close();
      })
      .finally(() => {
        // reset loading state since we've got response
        setLoading(false);
        //setSelectedCustomerId(customerId);
        
        return true;
      });
  }

  /**
   * Detect if we are on a mobile device
   */
  const detectMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }

  const filterControl = (
    <ResourceList.FilterControl
      searchValue={searchValue}
      onSearchChange={searchValue => setSearchValue(searchValue)}
    />
  );

  return (
    <div>
      <ResourceList
        resourceName={{
          singular: t('customer'),
          plural: t('customers')
        }}
        items={customers}
        loading={loading}
        renderItem={item => {
          return renderCustomer(item);
        }}
        filterControl={filterControl}
      />
      {renderPagination()}
    </div>
  );
}

export default CustomersList;