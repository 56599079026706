// react
import React, { useState } from 'react';
import {
  FooterHelp,
  Frame,
  Link,
  Page,
  TopBar } from '@shopify/polaris';
import { Route } from "react-router-dom";

import globalConfig from '../config/Global';

import Customers from '../components/Customers/Customers';
import FeedbackPage from '../pages/FeedbackPage';
import AppNavigation from '../components/AppNavigation';

// multilang
import { useTranslation, Trans } from 'react-i18next';

const FramesetPage = props => {
  // name initials parsing helper
  const getInitials = (name) => {
    return name ? Array.prototype.map.call(name.split(" "), function(x){ return x.substring(0,1).toUpperCase();}).join('') : '';
  }

  // Get initial customer name
  const getInitialCustomerName = () => {
    let name = sessionStorage.getItem('identity-switcher-customer-name');
    let customer = {
      name: name ? name : props.userData.name,
      initials: name ? getInitials(name) : getInitials(props.userData.name),
      detail: props.userData.shop
    }
    return customer;
  }

  // Update state (from child component) with new customer name when switching
  const getCustomerName = (name) => {
    let customer = {
      name: name ? name : props.userData.name,
      initials: name ? getInitials(name) : getInitials(props.userData.name),
      detail: props.userData.shop
    }
    setLoggedInCustomerName(customer);
  }
  
  const [loggedInCustomerName, setLoggedInCustomerName] = useState(getInitialCustomerName());
  const { t } = useTranslation();

  const [mobileNavigationState, setMobileNavigationState] = useState(false);

  const toggleMobileNavigationState = () => {
    setMobileNavigationState(!mobileNavigationState);
  }

  const listingPage = (
    <Page title={t("Login as customer")}>
      <Customers 
        title={globalConfig.title} 
        customerName={getCustomerName} 
        isSuperAgent={props.userData.isSuperAgent}
      />
      <FooterHelp>
        <Trans i18nKey="FooterHelp">
          <Link external url={globalConfig.footerLink.url}>
            Learn
          </Link>
          {' '}how to switch identities.
        </Trans>
      </FooterHelp>
    </Page>
  );
  
  // Send user data as props to feedback page
  const feedbackPage = (
    <FeedbackPage userData={props.userData} />
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      name={loggedInCustomerName.name}
      initials={loggedInCustomerName.initials}
      detail={loggedInCustomerName.detail}
    />
  );

  const topBarMarkup = (
    <TopBar 
      showNavigationToggle={true}
      onNavigationToggle={toggleMobileNavigationState}
      userMenu={userMenuMarkup} 
    />
  );

  return (
    <Frame
      topBar={topBarMarkup}
      navigation={<AppNavigation />}
      showMobileNavigation={mobileNavigationState}
      onNavigationDismiss={toggleMobileNavigationState}
    >
      <Route exact path="" render={() => listingPage} />
      <Route exact path="/feedback" render={() => feedbackPage} />
    </Frame>
  );
}

export default FramesetPage;