import React from 'react';
import {
  Banner,
  Card,
  Layout,
  Page
} from '@shopify/polaris';
import FeedbackForm from '../components/FeedbackForm';

// multilang
import { useTranslation } from 'react-i18next';

const FeedbackPage = props => {
  const { t } = useTranslation();

  return (
    <Page title={t("Feedback")}>
      <Layout>
        <Layout.Section>
          <Banner 
            status="info"
            children={t("Feedback_InfoBanner")}
          />
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <FeedbackForm
              subdomain="code-shopify-apps.zendesk.com"
              currentPageURL={window.location.href}
              userData={props.userData}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default FeedbackPage;