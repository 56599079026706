// react
import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';

// common
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import queryString from 'query-string';

// shopify polaris
import { AppProvider, Frame, Loading, TopBar } from '@shopify/polaris';
import '@shopify/polaris/styles.css';

// config
import globalConfig from './config/Global';
import firebaseConfig from './config/Firebase';

// components
import ErrorPage from './pages/ErrorPage';
import FramesetPage from './pages/FramesetPage';
import SwitchLoadingPage from './pages/SwitchLoadingPage';

// firebase related
import firebase from 'firebase/app';
import 'firebase/auth';

// multilang
import { getI18n } from 'react-i18next';

// translations
import enTranslations from './translations/polaris/en.json';
import deTranslations from './translations/polaris/de.json';
import frTranslations from './translations/polaris/fr.json';
import nlTranslations from './translations/polaris/nl.json';
import esTranslations from './translations/polaris/es.json';

// init firebase app
firebase.initializeApp(firebaseConfig.initialize);

// init history
const history = createBrowserHistory();

const App = props => {
  const [httpStatus, setHttpStatus] = useState(200);
  const [firebaseSignedIn, setFirebaseSignedIn] = useState(false);
  const [userData, setUserData] = useState({});

  // set polaris language file
  const multilangObj = getI18n();
  let polarisTranslations;
  switch (multilangObj.language) {
    case 'de' : 
      polarisTranslations = deTranslations; 
      break;
    case 'nl' : 
      polarisTranslations = nlTranslations; 
      break;
    case 'fr' : 
      polarisTranslations = frTranslations; 
      break;
    case 'en' : 
    default: 
      polarisTranslations = enTranslations; 
      break;
    case 'es' : 
      polarisTranslations = esTranslations; 
      break;
  }

  const firebaseGetUserData = () => {
    firebase.auth().onAuthStateChanged((user) => {
      new Promise((resolve, reject) => {
        if (user) {
          resolve(user);
        } else {
          reject(new Error("Unknown user"));
        }
      })
      .then(user => {
        return user.getIdTokenResult();
      })
      .then((idTokenResult) => {
        setUserData({
          app: idTokenResult.claims.aud,
          name: idTokenResult.claims.name,
          shop: idTokenResult.claims.shop,
          isSuperAgent: idTokenResult.claims.super_agent === 1 ? true : false
        });
        setFirebaseSignedIn(true);
      })
      .catch(error => {
        console.log(error);
        setHttpStatus(403);
      });
    });
  }

  const firebaseSignIn = (customToken) => {
    return firebase.auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInWithCustomToken(customToken))
      .then(() => {
        firebaseGetUserData();
      })
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        // @doc: https://firebase.google.com/docs/reference/js/firebase.auth.Error
        switch (error.code) {
          case "auth/invalid-custom-token":
          case "auth/invalid-user-token":
            setHttpStatus(400);
            break;
          case "auth/user-disabled":
          case "auth/user-token-expired":
            setHttpStatus(403);
            break;
          case "auth/too-many-requests":
            setHttpStatus(429);
            break;
          default:
            setHttpStatus(500);
        }
      });
  }

  
  // Do the following only once on page load
  useEffect(() => { 
    // if a token is given, authenticate with Firebase
    const requestQuery = queryString.parse(window.location.search);
    if (typeof requestQuery.token === "string") {
      firebaseSignIn(requestQuery.token)
        .then(() => {
          history.push("/");
        });
    } else {
      firebaseGetUserData();
    }
  }, []);


  // shows while signing in to Firebase
  const signingInPage = () => {
    return (
      <Frame
        topBar={(<TopBar />)}
      >
        <Loading />
      </Frame>
    );
  };

  // shows while there's a different http state then 200
  const httpStatusPage = () => {
    return (
      <AppProvider theme={globalConfig.theme}>
        <Frame
          topBar={(<TopBar />)}
        >
          <ErrorPage status={httpStatus} />
        </Frame>
      </AppProvider>
    );
  }

  // shows when signed in. Sending user data extracted from firebase token claims as props
  const framesetPage = () => {
    return (
      <FramesetPage userData={userData} />
    )
  }
  
  // return output
  if (httpStatus !== 200) {
    return httpStatusPage();
  } else {
    return (
      <Router>
        <AppProvider 
          theme={globalConfig.theme} 
          i18n={polarisTranslations}>
          <div className="App">
            <Switch>
              <Route path="/switch" component={SwitchLoadingPage} />
              <Route path="/" component={firebaseSignedIn ? framesetPage : signingInPage /* protected route */} />
            </Switch>
          </div>
        </AppProvider>
      </Router>
    );
  }
}

export default App;
