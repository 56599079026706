// react
import React from 'react';
import { Navigation } from '@shopify/polaris';

import globalConfig from '../config/Global';
import { withRouter } from "react-router-dom";

// multilang
import { useTranslation } from 'react-i18next';

const AppNavigation = withRouter(({history}) => {

  const { t } = useTranslation();

  return (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            label: t("Login as customer"),
            iconBody: globalConfig.icon,
            onClick: () => {
              history.push("/");
            }
          },
          // {
          //   label: 'Help',
          //   icon: 'help',
          //   disabled: true
          // },
        ]}
      />
      <Navigation.Section
        separator
        title={t("About this app")}
        fill={true}
        items={[
          {
            label: t("Feedback"),
            icon: 'conversation',
            onClick: () => {
              history.push("/feedback");
            }
          },
          {
            label: t("Made by CODE"),
            icon: 'external',
            onClick: () => window.open("https://www.code.nl")
          }
        ]}
      />
    </Navigation>);
});

export default AppNavigation;