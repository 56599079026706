import React from 'react';
import { Frame, Banner, Page, Layout, Card, ProgressBar, TextContainer, TopBar } from '@shopify/polaris';

// multilang
import { useTranslation } from 'react-i18next';

const SwitchLoadingPage = props => {

  const { t } = useTranslation();

  return (
    <Frame topBar={(<TopBar />)}>
      <Page>
        <Layout>
          <Layout.Section>
            <Card title={t("Switching identities")} sectioned>
              <TextContainer>
                <Banner status="info">
                  <p>
                    {t("Please wait while logging in...")}
                  </p>
                </Banner>
                <ProgressBar progress={85} size="small" /> 
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
}

export default SwitchLoadingPage;