/**
 * POST feedback data to Zendesk
 * https://developer.zendesk.com/rest_api/docs/core/requests#create-request
 *
 * @param {Object} data      - Feedback data payload
 * @param {String} subdomain - Subdomain of zendesk account eg https://${subdomain}
 *
 * @return {Object}          - Response from Zendesk
 *
 */

export const sendFeedback = async (data, subdomain) => {
  try {
    const headers = {
      "Content-Type": "application/json"
    };
    const response = await fetch(
      `https://${subdomain}/api/v2/requests.json`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};

/**
 * POST attachments data to Zendesk
 * https://developer.zendesk.com/rest_api/docs/support/attachments#upload-files
 *
 * @param {Array}  files      - The files to be attached to the request comment
 * @param {String} subdomain  - Subdomain of zendesk account eg https://${subdomain}
 *
 * @return {Object}           - Response from Zendesk
 *
 */
const delay = () => {
  return new Promise(resolve => setTimeout(resolve, 300));
}

const singleFileUpload = async (file, subdomain, uploadToken) => {
  await delay();
  try {
    const headers = {
      "Content-Type": "application/binary"
    };
    const response = await fetch(
      `https://${subdomain}/api/v2/uploads.json?filename=${file.name}&token=${uploadToken}`,
      {
        method: "POST",
        body: file,
        headers
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    return error;
  }
}

export const uploadFiles = async (files, subdomain) => {
  // Empty token variable to be filled at the end of first/each loop
  let uploadToken = '';

  for (const file of files) {
    const uploadResponse = await singleFileUpload(file, subdomain, uploadToken);
    uploadToken = uploadResponse.upload.token;
  }

  return uploadToken;
};

/**
 * Format a payload of form data to the format required by Zendesk
 *
 * @param {String} subject      - Zendesk ticket subject
 * @param {String} email        - Email provided by user giving feedback
 * @param {String} app          - Application the feedback is coming from
 * @param {String} username     - The user's name
 * @param {String} shop         - The users's shop
 * @param {String} feedback     - Feedback provided by user
 * @param {String} pageURL      - URL of page user submitted feedback from
 * @param {String} uploadToken  - Upload token tha references the files uploaded with the request 
 *
 * @return {Object}             - Payload for Zendesk Requests API
 *
 */

export const formatPayload = (
  subject,
  email,
  app,
  username,
  shop,
  feedback,
  pageURL,
  uploadToken
) => {
  const requester = email
    ? { name: username, email }
    : { name: username };

  // Format the feedback string's white spaces and line breaks to HTML 
  const formattedBody = feedback.replace(' ', '&nbsp;').replace('\n', '<br><br>');

  // Add the comment body
  const comment = {
    uploads: [uploadToken],
    html_body:
    `
      <p>${ formattedBody }</p>
      <table style="width: auto; margin-bottom: 20px">
        <tbody>
          <tr>
            <td style="background-color: #f2f2f2; text-align: right; padding: 6px 12px;">Username</td>
            <td style="padding: 6px 12px;">${ username ? username : 'unknown' }</td>
          </tr>
          <tr>
            <td style="background-color: #f2f2f2; text-align: right; padding: 6px 12px;">Shop</td>
            <td style="padding: 6px 12px;">${ shop ? shop : 'unknown' }</td>
          </tr>
          <tr>
            <td style="background-color: #f2f2f2; text-align: right; padding: 6px 12px;">App</td>
            <td style="padding: 6px 12px;">${ app ? app : 'unknown' }</td>
          </tr>
          <tr>
            <td style="background-color: #f2f2f2; text-align: right; padding: 6px 12px;">URL</td>
            <td style=" padding: 6px 12px;">${ pageURL ? pageURL : 'unknown' }</td>
          </tr>
        </tbody>
      </table>
    `
  }

  return {
    request: {
      requester,
      subject,
      comment
    }
  };
};