import React, { useState } from 'react';
import PropTypes from "prop-types";
import {
  Banner,
  Button,
  Caption,
  DropZone,
  Form,
  FormLayout,
  PageActions,
  Spinner,
  Stack,
  TextStyle,
  TextField,
  Thumbnail
} from '@shopify/polaris';
import { 
  sendFeedback,
  uploadFiles,
  formatPayload 
} from './FeedbackFormAPI';
import './FeedbackForm.css';

// multilang
import { useTranslation } from 'react-i18next';

const FeedbackForm = props => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    subject: '',
    message: '',
    submit: false
  });

  const [files, setFiles] = useState([]);
  const acceptedFileTypes = "image/bmp, image/gif, image/jpeg, image/png, text/plain, text/csv, text/richtext, text/rtx, text/rtf, application/rtf, application/pdf, application/msword, application/zip";
  const validFileTypesForThumbnail = ['image/gif', 'image/jpeg', 'image/png'];

  const handleChange = (field) => {
    return (value) =>  {
      setFormData({...formData, [field]: value});
      setErrors({...errors, [field]: ''});
    }
  }

  const handleSubmit = async event => {
    let errorEmail = '',
        errorSubject = '',
        errorMessage = '';

    // Validate the email field
    // Email regex pattern - if an ivalid email is entered, Zendesk API will return and error
    const regex = /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/; // eslint-disable-line
    if ((formData.email.length > 0 && !regex.test(formData.email)) || formData.email.length === 0) {
      errorEmail = t("Error_InvalidEmail");
    }

    // Validate the feedback subject field
    if (formData.subject.length === 0)
      errorSubject = t("Error_EmptyEmail");

    // Validate the feedback subject field
    if (formData.subject.length === 0)
      errorSubject = t("Error_Subject");

    // Validate the feedback message field
    if (formData.message.length < 30)
      errorMessage = t("Error_Message");

    // If any field errors present, set errors state and exit function
    if (errorEmail.length || errorSubject.length || errorMessage.length) {
      setErrors({
        ...errors,
        email: errorEmail,
        subject: errorSubject,
        message: errorMessage
      });
      return;
    }
    
    // If fields are valid, set state to loading and make API POST requests
    setIsLoading(true);
    
    // Setup upload token var
    let uploadToken = '';

    // Check if there are attachments to upload
    if (files.length) {
      // Start uploading overlay
      setIsUploading(true);

      // First we upload the attachments and retrieve the attachments/uploads token
      uploadToken = await uploadFiles(files, props.subdomain);

      // If we dont get a success response, set submitError to true to show error message and exit process
      if (!uploadToken) {
        setIsLoading(false);
        setErrors({
          ...errors,
          submit: true
        });
        return;
      }
    }

    // Gather form data and attachments and make the API POST request
    const payload = formatPayload(
      formData.subject,
      formData.email,
      props.userData.app,
      props.userData.name,
      props.userData.shop,
      formData.message,
      props.currentPageURL,
      uploadToken
    );
    const response = await sendFeedback(payload, props.subdomain);

    // If we dont get a success response, set submitError to true to show error message
    if (!response.request) {
      setIsLoading(false);
      setErrors({
        ...errors,
        submit: true
      });
      return;
    }

    // If we get a success response, clear form and set isSubmitted to true to show the success message
    setFormData({
      email: '',
      subject: '',
      message: ''
    });
    setFiles([]);
    setIsLoading(false);
    setIsUploading(false);
    setIsSubmitted(true);
  }

  const handleRemoveFile = i => {
    setFiles(filesList => {
      const list = filesList.filter((item, j) => i !== j);
      return list;
    });
  }

  const uploadedFilesMarkup = files.length > 0 && (
    <div className="feedback-form-uploads-wrap">
      { isUploading && 
        <div className="feedback-form-uploads-loading">
          <div>
            <Spinner
              size="large"
            />
          </div>
        </div> 
      }
      <Stack vertical>
        { files.map((file, i) => (
          <Stack
            alignment="center"
            key={i}
            wrap={false}
          >
            <Button
              icon="delete"
              onClick={() => handleRemoveFile(i)}
            />
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validFileTypesForThumbnail.indexOf(file.type) > 0
                ? window.URL.createObjectURL(file)
                : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
              }
            />
            <Stack.Item fill>
              {file.name}
              <Caption>
                <TextStyle variation="subdued">
                  {file.size} bytes
                </TextStyle>
              </Caption>
            </Stack.Item>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  const fileDropzoneMarkup = (
    <DropZone
      allowMultiple={true}
      accept={acceptedFileTypes}
      label={!files.length && t("Attachments")}
      overlayText={t("DropFilesToUpload")}
      onDrop={(newFiles, acceptedFiles, rejectedFiles) => {
        setFiles([...files, ...acceptedFiles]);
      }}
      disabled={isLoading}
    >
      <DropZone.FileUpload
        actionTitle={t("AddFile")}
        actionHint={t("OrDropFilesToUpload")}
      />
    </DropZone>
  );

  const formErrorMarkup = (
    <Banner
      status="critical"
      children={t("Feedback_FormSubmitErrorMessage")}
      onDismiss={() => setErrors({...errors, submit: false})}
    />
  );

  const formSuccessMarkup = (
    <Banner
      status="success"
      children={t("Feedback_FormSubmitSuccessMessage")}
      onDismiss={() => setIsSubmitted(false)}
    />
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          label={t("Email")}
          placeholder={t("EmailPlacholder")}
          value={formData.email}
          onChange={handleChange('email')}
          type="email"
          autoFocus={true}
          error={errors.email}
          disabled={isLoading}
        />

        <TextField
          label={t("Subject")}
          placeholder={t("SubjectPlaceholder")}
          value={formData.subject}
          onChange={handleChange('subject')}
          error={errors.subject}
          disabled={isLoading}
        />

        <TextField
          label={t("Message")}
          placeholder={t("MessagePlaceholder")}
          helpText={!errors.message && t("MessageHelpText")}
          value={formData.message}
          onChange={handleChange('message')}
          error={errors.message}
          multiline={6}
          showCharacterCount={true}
          disabled={isLoading}
        />
        
        { files.length > 0 && 
          <div>
            <label>{t("Attachments")}</label>
            {uploadedFilesMarkup}
          </div>
        }
        { !isUploading && fileDropzoneMarkup}

        { errors.submit && formErrorMarkup }
        { isSubmitted && formSuccessMarkup }

        <PageActions
          primaryAction={{
            submit: true,
            content: t("Feedback_Send"),
            loading: isLoading
          }}
        />
      </FormLayout>
    </Form>
  );
}

FeedbackForm.propTypes = {
  subdomain: PropTypes.string,
  currentPageURL: PropTypes.string,
  userData: PropTypes.object
};

export default FeedbackForm;